<template>
  <div>

    <!-- Table Container Card -->
    <b-card no-body class="mb-0">
      <div class="m-2">
        <!-- Table Top -->
        <b-row>
          <!-- Per Page -->
          <b-col cols="12" md="4"
            class="d-none d-sm-inline align-items-center justify-content-start mb-1 mb-md-0 pull-left">
            <label>Afficher</label>
            <v-select v-model="perPage" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :options="perPageOptions"
              :clearable="false" class="per-page-selector d-inline-block mx-50" />
            <label>Elements par page</label>
          </b-col>

          <!-- Search -->
          <b-col cols="12" md="8">
            <div class="d-flex align-items-center justify-content-end pull-right">
              <b-form-input v-model="searchQuery" class="d-inline-block mr-1" placeholder="Rechercher..." />
              <b-button variant="primary" :to="{ name: 'file-add-new' }" v-if="userIsAdmin == true">
                <span class="text-nowrap">Ajouter Fichier PDF</span>
              </b-button>
            </div>
          </b-col>
        </b-row>
      </div>

      <b-table ref="refDataListTable" class="position-relative" :items="fetchModuleFiles" responsive
        :fields="tableColumns" primary-key="id" :sort-by.sync="sortBy" show-empty empty-text="Aucun resultat trouvé"
        :sort-desc.sync="isSortDirDesc">
        <!-- Column: id -->
        <template #cell(id)="data">
          <span>
            FL0{{ data.item.id }}
          </span>
        </template>

        <!-- Column: Status -->
        <template #cell(status)="data">
          <b-badge pill :variant="`light-${resolveDataStatusVariant(data.item.status)}`" class="text-capitalize">
            {{ data.item.status }}
          </b-badge>
        </template>



        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <div class="text-nowrap">

            <b-button :id="`file-row-${data.item.id}-preview-icon`" variant="success" @click="downloadFile(data.item.id)">
              <feather-icon icon="DownloadIcon" size="16" class="mx-1" />
              <span class="text-nowrap">Télécharger ce Fichier PDF</span>
            </b-button>


            <b-tooltip title="Télécharger Fichier PDF" :target="`file-row-${data.item.id}-preview-icon`" />

            <b-dropdown variant="link" no-caret :right="$store.state.appConfig.isRTL" v-if="userIsAdmin == true">
              <template #button-content>
                <feather-icon icon="MoreVerticalIcon" size="16" class="align-middle text-body" />
              </template>
              <b-dropdown-item :to="{
                name: 'file-view',
                params: { id: data.item.id },
              }">
                <feather-icon icon="FileTextIcon" />
                <span class="align-middle ml-50">Détails</span>
              </b-dropdown-item>

              <b-dropdown-item :to="{
                name: 'file-edit',
                params: { id: data.item.id },
              }">
                <feather-icon icon="EditIcon" />
                <span class="align-middle ml-50">Modifier</span>
              </b-dropdown-item>

              <b-dropdown-item @click="onDeleteQuestion(data.item)" @refetch-data="refetchData">
                <feather-icon icon="TrashIcon" />
                <span class="align-middle ml-50">Supprimer</span>
              </b-dropdown-item>
            </b-dropdown>
          </div>
        </template>
      </b-table>
      <div class="mx-2 mb-2">
        <b-row>
          <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-start">
            <span class="text-muted">Affiché {{ dataMeta.from }} à {{ dataMeta.to }} sur
              {{ dataMeta.of }} Enregistements</span>
          </b-col>
          <!-- Pagination -->
          <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-end">
            <b-pagination v-model="currentPage" :total-rows="totalData" :per-page="perPage" first-number last-number
              class="mb-0 mt-1 mt-sm-0" prev-class="prev-item" next-class="next-item">
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BTooltip,
  BDropdown,
  BDropdownItem,
  BPagination,
} from "bootstrap-vue";
import vSelect from "vue-select";
import store from "@/store";
import { ref, onUnmounted } from "@vue/composition-api";
import { avatarText } from "@core/utils/filter";
import useModuleList from "./useList";
import storeModule from "./storeModule";
// import ListDataTable from "./ListDataTable.vue";
import ListDataTableFilters from "./ListDataTableFilters.vue";

// sweet alert
import Ripple from "vue-ripple-directive";
//
// alerts
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  components: {
    ListDataTableFilters,
    // ListDataTable,

    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BTooltip,
    BDropdown,
    BDropdownItem,
    BPagination,
    vSelect,
  },
  data() {
    return {
      userIsAdmin: JSON.parse(localStorage.getItem("userData")).role == "super-admin" ? true : false
    };
  },
  directives: {
    Ripple,
  },
  props: {},
  methods: {
    async downloadFile(fileID) {
      // load requirement
      await myApi
        .get(`download/` + fileID)
        .then((response) => {
          // default action
          if (response.data.status == 200) {

            this.$swal({
              title: "Action Réussi",
              text: "Le téléchargement va démarrer sous peu",
              icon: "question",
              timer: 2000,
              buttonsStyling: false,
            }).then((result) => {

            });

          } else {
            alert(response.data.message)
          }
        })
        .catch((error) => {
          //
          console.log(error);
          alert("Erreur lors du téléchargement")
        });
    },
    onDeleteQuestion(file) {
      this.$swal({
        title: "Action Irreversible",
        text: "Voulez-vous vraiment supprimer ?",
        icon: "question",
        timer: 10000,
        showCancelButton: true,
        confirmButtonText: "Oui [Confirmer Suppression]",
        cancelButtonText: "Annuler",
        customClass: {
          confirmButton: "btn btn-success",
          cancelButton: "btn btn-outline-primary ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          // yes
          this.onDelete(file);
        } else if (result.dismiss === "cancel") {
          // stay here
        }
      });
    },
  },
  setup() {
    const APP_STORE_MODULE_NAME = "app-file";

    // Register module
    if (!store.hasModule(APP_STORE_MODULE_NAME))
      store.registerModule(APP_STORE_MODULE_NAME, storeModule);

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(APP_STORE_MODULE_NAME))
        store.unregisterModule(APP_STORE_MODULE_NAME);
    });

    const onDelete = (file) => {
      store
        .dispatch(APP_STORE_MODULE_NAME + "/deleteModule", file.id)
        .then(() => {
          // show confirmation notification
          refetchData();
        });
    };

    const isAddNewDataSidebarActive = ref(false);

    const statusOptions = [
      { label: "En Activité", value: "active" },
      { label: "Inactif", value: "inactive" },
      { label: "Non-Spécifié", value: "pending" },
    ];

    const {
      fetchModuleFiles,
      tableColumns,
      perPage,
      currentPage,
      totalData,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refDataListTable,
      refetchData,

      // UI
      resolveDataTypeVariant,
      resolveDataTypeIcon,
      resolveDataStatusVariant,

      // Extra Filters
      typeFilter,
      statusFilter,
    } = useModuleList();

    return {
      // Sidebar
      isAddNewDataSidebarActive,

      fetchModuleFiles,
      tableColumns,
      perPage,
      currentPage,
      totalData,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refDataListTable,
      refetchData,

      onDelete,

      // Filter
      avatarText,

      // UI
      resolveDataTypeVariant,
      resolveDataTypeIcon,
      resolveDataStatusVariant,

      statusOptions,

      // Extra Filters
      statusFilter,
    };
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
